<template>
  <div>
    <nurse-table
      :canDelete="true"
      viewRoute="SuperAdminNurseDetails"
      viewDetailRoute="SuperAdminNurseDetailView"
      :canCreate="true"
      createRoute="SuperAdminNurseCreate"
      :canAssignClinic="true"
    />
  </div>
</template>

<script>
import nurseTable from "../../components/nurses/nurseTable.vue";

export default {
  components: {
    nurseTable
  },
  data: () => ({})
};
</script>
